import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { MobDevice } from "./subComponent/subComponents";

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Серийный номер"
        withPadding
      />
    ),
    accessor: "sn",
    minWidth: 184,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Модель"
        withPadding
      />
    ),
    accessor: "meter_name",
    minWidth: 230,
    filter: "includesSome",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Порт"
        withPadding
      />
    ),
    accessor: "port",
    minWidth: 100,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата подключения"
        withPadding
      />
    ),
    accessor: "dt_create",
    minWidth: 160,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Адрес"
        withPadding
      />
    ),
    accessor: "address",
    minWidth: 300,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="А прием, кВт*ч"
        withPadding
      />
    ),
    accessor: "ActEpr",
    minWidth: 140,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата последней передачи данных"
        withPadding
      />
    ),
    accessor: "dt_verify",
    minWidth: 260,
    sortType: "datetime",
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Реле нагрузки"
        withPadding
      />
    ),
    accessor: "rele_status",
    minWidth: 124,
    sortType: "basic",
    canFilter: true,
    filter: "arrIncludesSome",
  },
];

export const tableMobStructure = [
  {
    accessor: "sn",
    Row: MobDevice,
  },
  {
    accessor: "meter_name",
    canFilter: true,
  },
  {
    accessor: "dt_create",
    sortType: "datetime",
  },
  {
    accessor: "ActEpr",
  },
  {
    accessor: "dt_verify",
    sortType: "datetime",
  },
  {
    accessor: "rele_status",
    canFilter: false,
    sortType: "basic",
  },
  {
    accessor: "address",
  },
];
